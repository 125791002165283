<template>
    <div class="driverManagement">
        司机管理
    </div>
</template>
    
<script>
export default {
    data() {
        return {

        }
    },
}
</script>
    
<style></style>